@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../../mixins';

.ContentBlock {
  @include mixins.falcon-text-component-max-width;

  &__container {
    &:not(:first-of-type) {
      margin-top: 2 * tokens.bpk-spacing-xl();
    }
  }

  &__header {
    span {
      margin-top: tokens.bpk-spacing-sm();
    }
  }

  &__itemList {
    margin-top: tokens.bpk-spacing-lg();
    color: tokens.$bpk-text-primary-day;
    line-height: tokens.$bpk-line-height-lg;
  }

  &__columns {
    @include breakpoints.bpk-breakpoint-above-mobile {
      display: flex;
      justify-content: space-between;
    }
  }

  &__column {
    @include breakpoints.bpk-breakpoint-mobile {
      &:not(:last-child) {
        margin-bottom: tokens.bpk-spacing-base();
      }
    }

    @include breakpoints.bpk-breakpoint-above-mobile {
      flex-basis: calc((100% - #{tokens.bpk-spacing-base()}) / 2);

      div[role*='listitem'] {
        padding-right: 0;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;

    h3 {
      padding-bottom: tokens.bpk-spacing-md();
      color: tokens.$bpk-text-primary-day;

      @include typography.bpk-heading-4;

      @include breakpoints.bpk-breakpoint-mobile {
        @include typography.bpk-heading-5;
      }
    }

    a {
      color: tokens.$bpk-color-sky-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:visited,
      &:active {
        color: tokens.$bpk-color-sky-blue;
      }
    }

    &:not(:last-child) {
      margin-bottom: tokens.bpk-spacing-base();
    }
  }

  &__imgBox {
    margin-inline-end: tokens.bpk-spacing-base();

    svg {
      fill: tokens.$bpk-core-primary-day;

      @include utils.bpk-rtl {
        left: auto;
      }
    }
  }

  &__itemBody {
    color: tokens.$bpk-text-primary-day;

    p {
      line-height: tokens.$bpk-line-height-lg;

      @include typography.bpk-body-default;
    }

    a {
      color: tokens.$bpk-core-accent-day;
      text-decoration: none;
    }
  }

  &__contentfulTemplate {
    h2 {
      margin: 0;
      padding-bottom: tokens.bpk-spacing-sm();

      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-mobile {
        @include typography.bpk-heading-3;
      }
    }

    h3 {
      margin: 0;
      padding-bottom: tokens.bpk-spacing-md();

      @include typography.bpk-heading-4;

      @include breakpoints.bpk-breakpoint-mobile {
        @include typography.bpk-heading-5;
      }

      &:empty {
        display: none;
      }
    }

    p {
      margin: 0;

      @include typography.bpk-body-default;

      &:empty {
        display: none;
      }
    }

    a {
      color: tokens.$bpk-color-sky-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:visited,
      &:active {
        color: tokens.$bpk-color-sky-blue;
      }
    }

    div[role='list'] {
      margin-top: tokens.bpk-spacing-lg();
      color: tokens.$bpk-text-primary-day;
    }

    div[role='listitem'] {
      position: relative;
      padding: 0 0 0 tokens.bpk-spacing-xxl();

      @include utils.bpk-rtl {
        padding: 0 tokens.bpk-spacing-xxl() 0 0;
      }

      &:not(:last-child) {
        margin-bottom: tokens.bpk-spacing-base();
      }
    }

    svg {
      position: absolute;
      top: 1.6 * tokens.$bpk-one-pixel-rem;
      left: 0;
      width: tokens.$bpk-icon-size-lg;
      height: tokens.$bpk-icon-size-lg;
      fill: tokens.$bpk-color-sky-gray;

      @include utils.bpk-rtl {
        right: 0;
        left: auto;
      }
    }
  }

  &__contentfulTemplateTwoColumns {
    @include breakpoints.bpk-breakpoint-above-mobile {
      div[role='list'] {
        columns: 2;

        div[role*='listitem'] {
          height: 100%;
          padding-right: 0;
          overflow: auto;
        }
      }
    }
  }
}
